

<template>
    <!-- Component: organisms/timeline -->
    <div id="rollup-container" v-if="getCookie('timeline') == false">
        <div class="toggle-container">
            <p class="expand-label expand-label--on"
               @click="clickCollapse();"
            >- <span>Hide Fiscal Year Timeline</span></p>
            <p class="expand-label expand-label--off is-active"
               @click="clickCollapse();"
            >+ <span>Show Fiscal Year Timeline</span></p>
        </div>
        <div id="timeline-container" class="container">
            <p class="general-container__title">{{ title }}</p>
            <div class="scroll-wrapper">
                <iframe title="Timeline" name="Timeline" style="border-width:0;" onmousewheel="" frameborder="0" scrolling="no" id="tl-timeline-iframe" width="100%" height="480" src="https://www.tiki-toki.com/timeline/embed/1674073/1951906709/"></iframe>
                <iframe title="Timeline Mobile" name="Timeline Mobile" style="border-width:0;" onmousewheel="" frameborder="0" scrolling="no" id="tl-timeline-iframe__mobile" width="100%" height="480" src="https://www.tiki-toki.com/timeline/embed/1770959/1951906709/"></iframe>

                <div class="legend">

                    <div class="legend__title">Key Events Legend:</div>

                    <div class="legend__item">
                        <span class="color color--light"></span>
                        <span class="legend__text">Budget Event</span>
                    </div>

                    <div class="legend__item">
                        <span class="color color--dark"></span>
                        <span class="legend__text">Session Event</span>
                    </div>

                    <div class="reset-button__container">
                        <button class="reset-button" @click="refreshIframe();">Go To Today</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- this if/else is hacky, but it works -->
    <div id="rollup-container" v-else>
        <div class="toggle-container">
            <p class="expand-label expand-label--on is-active" @click="clickCollapse();">- <span>Hide
                <Tooltip :tooltipText="definitions && definitions['Fiscal Year'] && definitions['Fiscal Year'].definition ? definitions['Fiscal Year'].definition : 'Loading...'">
                    <span class='has-tooltip'>
                        Fiscal Year
                    </span>
                </Tooltip>
                 Timeline</span></p>
            <p class="expand-label expand-label--off" @click="clickCollapse();">+ <span>Show
                <Tooltip :tooltipText="definitions && definitions['Fiscal Year'] && definitions['Fiscal Year'].definition ? definitions['Fiscal Year'].definition : 'Loading...'">
                    <span class='has-tooltip'>
                        Fiscal Year
                    </span>
                </Tooltip>
                 Timeline</span></p>
        </div>
        <div id="timeline-container" class="container is-active">
            <p class="general-container__title">{{ title }}</p>
            <div class="scroll-wrapper">
                <iframe title="Timeline" name="Timeline" style="border-width:0;" onmousewheel="" frameborder="0" scrolling="no" id="tl-timeline-iframe" width="100%" height="480" src="https://www.tiki-toki.com/timeline/embed/1674073/1951906709/"></iframe>
                <iframe title="Timeline Mobile" name="Timeline Mobile" style="border-width:0;" onmousewheel="" frameborder="0" scrolling="no" id="tl-timeline-iframe__mobile" width="100%" height="480" src="https://www.tiki-toki.com/timeline/embed/1770959/1951906709/"></iframe>

                <div class="legend">

                    <div class="legend__title">Key Events Legend:</div>

                    <div class="legend__item">
                        <span class="color color--light"></span>
                        <span class="legend__text">Budget Event</span>
                    </div>

                    <div class="legend__item">
                        <span class="color color--dark"></span>
                        <span class="legend__text">Session Event</span>
                    </div>

                    <div class="reset-button__container">
                        <button class="reset-button" @click="refreshIframe();">Go To Today</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- End Component: organisms/timeline -->
</template>

<script>
    import { reactive, ref, computed } from 'vue';
    import { useStore } from "vuex";
    import Tooltip from "./tooltip";

    export default {
        name: 'Timeline',
        components: {
            Tooltip
        },
        props: [
            'title'
        ],
        methods: {
            receiveMessage (event) {
                console.log(event.data)
            },

            refreshIframe() {
                var ifr = document.getElementsByName('Timeline')[0];
                ifr.src = ifr.src;
            },

            clickCollapse() {
                let element = document.getElementById('timeline-container');
                let toggleA = document.getElementsByClassName('expand-label')[0];
                let toggleB = document.getElementsByClassName('expand-label')[1];
                let currentDate = new Date();
                let futureDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 3, currentDate.getDate());
                let expand = false;
                if (element.classList.contains('is-active')) {
                    element.classList.remove('is-active');
                    toggleA.classList.remove('is-active');
                    toggleB.classList.add('is-active');
                    document.cookie = "timeline=false; expires=" + futureDate.toUTCString();
                    //console.log(document.cookie);
                } else {
                    element.classList.add('is-active');
                    toggleA.classList.add('is-active');
                    toggleB.classList.remove('is-active');
                    document.cookie = "timeline=true; expires=" + futureDate.toUTCString();
                    //console.log(document.cookie);
                }
            },

            getCookie(cname) {
                let name = cname + "=";
                let decodedCookie = decodeURIComponent(document.cookie);
                let ca = decodedCookie.split(';');
                for(let i = 0; i <ca.length; i++) {
                    let c = ca[i];
                    while (c.charAt(0) == ' ') {
                        c = c.substring(1);
                    }
                    if (c.indexOf(name) == 0) {
                        return c.substring(name.length, c.length);
                    }
                }
                return "unset";
            }

        },
        mounted () {
            window.addEventListener('message', this.receiveMessage);
            if (window.postMessage) {
                var tlFrame = document.getElementById("tl-timeline-iframe");
                var tlMouseupFunc = function() {
                    if (tlFrame.contentWindow && tlFrame.contentWindow.postMessage) {
                        tlFrame.contentWindow.postMessage("mouseup","*");
                    }

                    var tlFrameModal = document.getElementById("tl-timeline-iframe__modal");
                    if (tlFrameModal.contentWindow && tlFrameModal.contentWindow.postMessage) {
                        tlFrameModal.contentWindow.postMessage("mouseup","*");
                    }
                }
                if (typeof window.addEventListener != "undefined") {
                    tlFrame.addEventListener("mouseup", tlMouseupFunc, false);
                } else if (typeof window.attachEvent != "undefined") {
                    tlFrame.attachEvent("onmouseup", tlMouseupFunc);
                }
            }
        },
        beforeDestroy () {
            window.removeEventListener('message', this.receiveMessage);
        },
        setup(props) {
            const store = useStore();
            const definitions = computed(() => store.getters.definitions);

            return {
                definitions
            };

        },
        created() {}
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/_variables.scss";
    @import "../../assets/scss/mixins/_bemify.scss";

    #tl-timeline-iframe {
        display: block;
        @media screen and (max-width: 768px) {
            display: none;
        }
    }

    #tl-timeline-iframe__mobile {
        display: none;
        @media screen and (max-width: 768px) {
            display: block;
        }
    }

    iframe {
        border-radius: 10px;
    }
    .general-container__title {
        text-align: center;
    }
    .scroll-wrapper {
    	-webkit-overflow-scrolling: touch;
  	    overflow-y: scroll;
        @media screen and (max-width: 1028px) {
            margin: 0 15px;
        }
    }
    @include block('legend') {
        padding: 10px;
        background-color: $blue--dark;
        position: relative;
        top: -15px;
        @include element('item') {
            display: inline-block;
            margin-right: 20px;
            color: $white;
            .color {
                display: inline-block;
                width: 20px;
                height: 20px;
                margin-right: 5px;
                border: 2px solid $white;
                &.color--light {
                    background-color: $blue--med;
                }
                &.color--dark {
                    background-color: $blue--dark;
                }
            }
        }
        @include element('text') {
            display: inline-block;
            position: relative;
            top: -3px;
        }
        @include element('title') {
            display: block;
            width: 100%;
            color: $white;
            font-weight: 600;
            margin-bottom: 10px;
        }
    }
    @include block('reset-button') {
        border: 2px solid $white;
        padding: 5px 10px;
        color: $white;
        background-color: $blue--med;
        font-size: 1em;
        position: relative;
        top: -6px;
        @include element('container') {
            float: right;
            display: inline-block;
        }
    }

    #timeline-container {
        display: none;
        transition: all 1s ease-in-out;
        &.is-active {
            display: block;
        }
    }
    .toggle-container {
        display: inline-block;
        text-align: center;
        width: 100%;
    }
    @include block('expand-label') {
        display: none;
        font-weight: bold;
        padding-bottom: 0px;
        background-color: $blue--dark;
        color: $white;
        padding: 5px 10px;
        margin-bottom: 0px;
        border-radius: 0.25rem;
        transition: all .5s ease;
        cursor: pointer;
        span {
            text-decoration: underline;
        }
        &.is-active {
            display: inline-block;
        }
        @include modifier('off') {
            text-align: center;
            background-color: #022c43;
            font-size: 21px;
            padding: 15px 25px 15px 25px;
            color: #fff;
            @media screen and (max-width: 900px) {
                font-size: 18px;
            }
        }
    }
</style>

