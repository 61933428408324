<template>
    <!-- Page: pages/dashboard -->
    <div class="container">
        <alert/>
    </div>

    <br/>

    <div class="container mobile-contain" style="display: flow-root;">
        <h1 v-if="user && user.first_name">Welcome, {{ user.first_name }}</h1>
        <userTools/>
    </div>

    <br/>
    <br/>

    <timeLine :title="'Key Events for July 2022 - June 2025'"></timeLine>

    <br/>

    <a href="/browse-agencies" class="container mobile-contain org-chart-container" style="display: flow-root;">
        <img src="@/assets/svg/org-chart.svg" alt="org chart icon" class="chart-icon" />
        <span>See Full Executive Branch Organizational Chart</span>
    </a>

    <!--
    <div class="container">
        <key-events-block></key-events-block>
    </div>
    -->

    <br/>

    <template v-if="isMobile()">
        <div class="accordions accordions-custom dashboard-accordions">
            <div class="accordion">
                <input type="checkbox" id="chck5">
                <label class="accordion-label" for="chck5">
                    <img class="header-icon" src="@/assets/img/icons/alarm-clock.png" alt="recent activity icon" />
                    <Tooltip tooltipText="Shows recent changes related to agencies, positions, and contacts that you have bookmarked." position="bottom">
                        <p :class="(isMobile()) ? 'header-title mobile-header-title has-a-tooltip' : 'header-title has-a-tooltip'">Recent Activity</p>
                    </Tooltip>
                </label>

                <div class="accordion-content">
                    <div class="container">
                        <since-you-last-block :mobile="isMobile()"></since-you-last-block>
                    </div>
                </div>
            </div>
        </div>

        <br/>
        <br/>
    </template>
    <template v-else>
        <Tooltip tooltipText="Shows recent changes related to agencies, positions, and contacts that you have bookmarked." position="bottom">
            <p :class="(isMobile()) ? 'header-title mobile-header-title has-a-tooltip' : 'header-title has-a-tooltip'">Recent Activity</p>
        </Tooltip>

        <div class="container">
            <since-you-last-block :mobile="isMobile()"></since-you-last-block>
        </div>

        <br/>
        <br/>
    </template>

    <template v-if="isMobile()">
        <div class="accordions accordions-custom dashboard-accordions">
            <div class="accordion">
                <input type="checkbox" id="chck4">
                <label class="accordion-label" for="chck4">
                    <img class="header-icon" src="@/assets/img/icons/notifications.png" alt="govbuzz icon" />
                    <Tooltip tooltipText="Shows recent tweets from some key members of state leadership" position="bottom" cClass="l60">
                        <p :class="(isMobile()) ? 'header-title mobile-header-title has-a-tooltip' : 'header-title has-a-tooltip'">GovBuzz</p>
                    </Tooltip>
                </label>

                <div class="accordion-content">
                    <div class="container">
                        <govbuzz :mobile="isMobile()"></govbuzz>
                    </div>
                </div>
            </div>
        </div>

        <br/>
        <br/>
    </template>
    <template v-else>
        <Tooltip tooltipText="Shows recent tweets from some key members of state leadership" position="bottom">
            <p :class="(isMobile()) ? 'header-title mobile-header-title has-a-tooltip' : 'header-title has-a-tooltip'">GovBuzz</p>
        </Tooltip>

        <div class="container">
            <govbuzz :mobile="isMobile()"></govbuzz>
        </div>

        <br/>
        <br/>
    </template>

    <div class="container">
        <div class="columns">
            <div class="column full960">

                <template v-if="isMobile()">
                    <div class="accordions accordions-custom">
                        <div class="accordion">
                            <input type="checkbox" id="chck3">
                            <label class="accordion-label" for="chck3">
                                <img class="header-icon" src="@/assets/img/icons/suitcase.png" alt="recent position changes icon" />
                                <!--                                <p :class="(isMobile()) ? 'header-title mobile-header-title' : 'header-title'">Recent Position Changes</p>-->
                                <p :class="(isMobile()) ? 'header-title mobile-header-title' : 'header-title'">Procurements Closing Soon</p>
                            </label>

                            <div class="accordion-content">
                                <procurements-news></procurements-news>
                            </div>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <p :class="(isMobile()) ? 'header-title mobile-header-title' : 'header-title'">Procurements Closing Soon</p>
                    <procurements-news></procurements-news>
                </template>

            </div>
            <div class="column full960 mobile-mt30">

                <template v-if="isMobile()">
                    <div class="accordions accordions-custom">
                        <div class="accordion">
                            <input type="checkbox" id="chck2">
                            <label class="accordion-label" for="chck2">
                                <img class="header-icon mh98" src="@/assets/img/icons/money-bag.png" alt="budget remaining icon" />
                                <p :class="(isMobile()) ? 'header-title mobile-header-title' : 'header-title'">Top 5 Agencies by Budget Remaining</p>
                            </label>

                            <div class="accordion-content">
                                <top-agencies-block></top-agencies-block>
                                <!--saved-contact-block v-if="hasBookmarks"></saved-contact-block-->
                            </div>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <p :class="(isMobile()) ? 'header-title mobile-header-title' : 'header-title'">Top 5 Agencies by Budget Remaining</p>
                    <top-agencies-block></top-agencies-block>
                    <!--saved-contact-block v-if="hasBookmarks"></saved-contact-block-->
                </template>
            </div>
        </div>
    </div>

    <br/>
    <br/>

    <template v-if="isMobile()">
        <div class="accordions accordions-custom">
            <div class="accordion">
                <input type="checkbox" id="chck1">
                <label class="accordion-label" for="chck1">
                    <img class="header-icon" src="@/assets/img/icons/newspaper.png" alt="recent agency news icon" />
                    <p :class="(isMobile()) ? 'header-title mobile-header-title' : 'header-title'">In The News</p>
                </label>

                <div class="accordion-content">
                    <div class="container">
                        <div class="columns">
                            <div class="column">
                                <recent-news-all-block :num="4"></recent-news-all-block>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </template>
    <template v-else>
        <div class="container">
            <div class="columns">
                <div class="column">
                    <p :class="(isMobile()) ? 'header-title mobile-header-title' : 'header-title'">In The News</p>
                    <recent-news-all-block :title="'Recent Agency News'" :num="4"></recent-news-all-block>
                </div>
            </div>
        </div>
    </template>

    <!--<template v-if="isMobile()">
        <div class="accordions accordions-custom">
            <div class="accordion">
                <input type="checkbox" id="chck6">
                <label class="accordion-label" for="chck6">
                    <img class="header-icon" src="@/assets/img/icons/newspaper.png" alt="recent agency news icon" />
                    <p :class="(isMobile()) ? 'header-title mobile-header-title' : 'header-title'">Executive News</p>
                </label>

                <div class="accordion-content">
                    <div class="container">
                        <div class="columns">
                            <div class="column">
                                <recent-news-rss/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </template> 
    <template v-else>
        <div class="container">
            <div class="columns">
                <div class="column">
                    <p :class="(isMobile()) ? 'header-title mobile-header-title' : 'header-title'">Executive News</p>
                    <recent-news-rss/>
                </div>
            </div>
        </div>
    </template>-->


    <!-- End Page: pages/dashboard -->
</template>

<script>
    import Alert from "@/alivue/components/alert.vue";
    import UserTools from "@/alivue/components/user-tools.vue";
    import SinceYouLastBlock from '@/alivue/components/since-you-last-block.vue';
    import InterestedInBlock from '@/alivue/components/interested-in-block.vue';
    import TopAgenciesBlock from '@/alivue/components/top-agencies-block.vue';
    import SavedContactBlock from '@/alivue/components/saved-contact-block.vue';
    import SearchBlock from '@/alivue/components/search-block.vue';
    import KeyEventsBlock from '@/alivue/components/key-events-block.vue';
    import TimeLine from '@/alivue/components/timeline.vue';
    import {computed, onMounted, ref} from "vue";
    import { useStore } from "vuex";
    import { ACTION_TYPES } from "@/constants";
    import useUser from "@/helpers/useUser";
    import RecentNewsAllBlock from '@/alivue/components/recent-news-all-block.vue';
    import Tooltip from "@/alivue/components/tooltip.vue";
    import Govbuzz from "@/alivue/components/govbuzz.vue";


    import RequestHandler from "@/handler/RequestHandler";
    import ProcurementTablePair from "./procurement-table-pair.vue";
    import ProcurementsNews from "./procurements-news.vue";
    import RecentNewsRss from "./recent-news-rss.vue";

    export default {
        components: {
            RecentNewsRss,
            ProcurementsNews,
            ProcurementTablePair,
            Alert,
            UserTools,
            SinceYouLastBlock,
            InterestedInBlock,
            SavedContactBlock,
            SearchBlock,
            KeyEventsBlock,
            TimeLine,
            TopAgenciesBlock,
            RecentNewsAllBlock,
            Tooltip,
            Govbuzz
        },
        setup() {
            const store = useStore();
            const bookmarkedIdsLoaded = computed(() => store.getters.isBookmarkedIdsLoaded);
            const hasBookmarks = computed(
                () => store.getters.isBookmarkedIdsLoaded && store.getters.bookmarkedIds.length > 0
            );
            const {user} = useUser();

            const sortFieldClosing = ref('end_date');
            const sortIsAscClosing = ref(true);
            const procurementClosing = ref([]);
            const procurementClosingLoading = ref(true);
            const procurementsSortedClosing  = ref([]);

            const loadProcurementsClosing = () => {
                RequestHandler.loadProcurementsClosing(
                    sortFieldClosing.value,
                    sortIsAscClosing.value,
                ).then(data => {
                    procurementClosingLoading.value = false;
                    procurementClosing.value = data.ContentList.list;
                    setClosingProcurements();
                });
            };
            const setClosingProcurements = () => {
                let d = procurementClosing.value;

                function compare(a, b) {
                    if (a[sortFieldClosing.value] < b[sortFieldClosing.value]) {
                        return sortIsAscClosing.value ? -1 : 1;
                    }
                    if (a[sortFieldClosing.value] > b[sortFieldClosing.value]) {
                        return sortIsAscClosing.value ? 1 : -1;
                    }
                    return 0;
                }

                procurementsSortedClosing.value = d.sort(compare);
            };

            onMounted(() => {
                loadProcurementsClosing()
                if (!bookmarkedIdsLoaded.value) {
                    store.dispatch(ACTION_TYPES.loadBookmarkIds);
                }

                if ((/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) || (window.innerWidth < 992)) {
                    if (isMobile() === true) {
                        let headers = document.getElementsByClassName('header-title');
                        for (let i = 0; i < headers.length; i++) {
                            headers[i].className += " " + "mobile-header-title";
                        }
                    }
                }
            });

            return {
                hasBookmarks,
                user,
                loadProcurementsClosing,
                setClosingProcurements,
                sortFieldClosing,
                sortIsAscClosing,
                procurementClosing,
                procurementClosingLoading,
                procurementsSortedClosing,

            }
        },
        methods: {
            isMobile() {
                if ((/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) || (window.innerWidth < 992)) {
                    return true
                } else {
                    return false
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/_variables.scss";
    @import "../../assets/scss/mixins/_bemify.scss";
    @import "../../assets/scss/partials/_accordion-checkbox.scss";

    .header-title {
        font-weight: 600;
        font-family: Fira Sans,sans-serif;
        font-size: 28px;
        margin-bottom: .5em;
        color: #022c43;
        &.has-a-tooltip {
            border-bottom: 1px dashed $blue--med;
        }
    }

    .mobile-header-title {
        text-decoration: underline;
    }

    .header-icon {
        max-width: 60px;
        max-height: 60px;
        display: inline-block;
        float: left;
        margin-right: 15px;
        position: relative;
        top: -10px;
    }

    @include block('general-container') {
        @include element('title') {
            font-weight: 600;
            font-family: $header;
            font-size: 24px;
            margin-bottom: 0.5em;
        }
    }

    .filter-wrapper {
        max-width: 50%;
        margin: auto;
        margin-top: 20px;
        @media screen and (max-width: 768px) {
            width: 90%;
            max-width: 90%;
        }
        .search-block {
            margin-bottom: 30px;
        }
        .mb-30 {
            margin-bottom: 30px;
        }
    }
    .mobile-contain {
        h1 {
            float: left;
        }
        @media screen and (max-width: 1021px) {
            margin-left: 15px;
            margin-right: 15px;
            h1 {
                text-align: center;
                float: none;
            }
        }
    }
    .mobile-contain {
        h1 {
            float: left;
        }
        @media screen and (max-width: 1021px) {
            margin-left: 15px;
            margin-right: 15px;
            h1 {
                text-align: center;
                float: none;
            }
        }
    }
    .org-chart-container {
        text-align: center;
        background-color: $primary;
        font-weight: 600;
        font-size: 26px;
        padding: 15px 25px 25px 25px;
        transition: all 0.5s ease;
        color: $white;
        margin-bottom: 30px;
        &:hover, &:focus, &:active {
            background-color: $blue--med;
            color: $white;
        }
        @media screen and (max-width: 900px) {
            font-size: 18px;
        }
        span {
            @media screen and (max-width: 900px) {
                float: left;
                text-align: center;
                width: 100%;
            }
        }
    }
    .chart-icon {
        width: 50px;
        position: relative;
        top: 10px;
        margin-right: 15px;
        @media screen and (max-width: 900px) {
            top: 0px;
        }
    }

    @media screen and (max-width: 959px) {
        .full960 {
            width: 100%;
            flex-basis: auto;
        }
        .mobile-mt30 {
            margin-top: 30px;
        }
    }
</style>
