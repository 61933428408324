<template>
    <!-- Component: organisms/recent-news-all-block -->
    <general-container :customClass="customClass" :title="title ? title: ''" :isLocked="!items || items.length === 0">

        <preloader v-if="loading"/>

        <info-block-news
            v-if="!loading && items && items.length"
            v-for="item in resultsFiltered"
            :title="item.title"
            :desc="item.content"
            :url="item.link"
            :date="item.published"
            :agency="item.agency.AgencyRef"
        />
        <div v-if="!loading && (!items || items.length === 0)" class="no-data">
            <p class="no-data__icon">🧐</p>
            <p class="no-data__title">Hmmmm...</p>
            <p>It looks like there is no recent news. We'll keep checking and put the news here when it drops!</p>
        </div>

    </general-container>
    <!-- End Component: organisms/recent-news-all-block -->
</template>

<script>
    import GeneralContainer from "./general-container"
    import InfoBlockNews from "./info-block--news"
    import ReadMoreLink from "./read-more-link"
    import RequestHandler from "@/handler/RequestHandler";
    import { onMounted, ref } from "vue";
    import Preloader from "@/alivue/components/preloader";

    export default {
        name: 'RecentNewsAllBlock',
        props: [ 'title', 'num', ],
        components: {
            GeneralContainer,
            InfoBlockNews,
            ReadMoreLink,
            Preloader,
        },
        computed: {
            resultsFiltered () {
                this.items.sort( ( a, b) => {
                    return new Date(a.date) - new Date(b.date);
                });
                return this.items;
            }
        },
        data() {
            return {
                customClass: "recent-news-block",
                readMore: "See All Recent News",
                title: ""
            }
        },
        setup(props, context) {
            const loading = ref(true);
            const items = ref([]);

            onMounted(() => {
                RequestHandler.loadRecentNewsAll(props.num).then((list) => {
                    items.value = list;
                    loading.value = false;
                }).catch(error => {
                    loading.value = false;
                    console.error(error);
                });
            });
            return {items, loading};
        },

    }
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/_variables.scss";
    @import "../../assets/scss/mixins/_bemify.scss";
/*
    .media + .media {
        border-top: 1px solid $blue--med;
    }
*/
    @include block('no-data') {
        font-weight: 600;
        font-family: $header;
        font-size: 1.25rem;
        text-align: center;
        max-width: 800px;
        margin: 0 auto;
        @include element('title') {
            font-size: 1.5rem;
            margin-bottom: 5px;
        }
        @include element('icon') {
            font-size: 3.5rem;
            margin-bottom: -15px;
        }
    }
</style>

