<template>
    <!-- Component: organisms/govbuzz -->
    <general-container :customClass="customClass" :isLocked="false">

        <template
        v-if="updates && updates.length"
        v-for="item in updates"
        :key="item.contentId"
        >
        <component v-bind="item" v-bind:is="getComponent(item)" v-if="item.class === 'TweetRef'"/>
        </template>
        
        <preloader v-if="loading"></preloader>
        <p v-if="!loading && updates.length == 0">Please bookmark more Contacts, Positions, Agencies, etc. to see recent updates.</p>

    </general-container>
    <!-- End Component: organisms/govbuzz -->
</template>

<script>
    import GeneralContainer from "./general-container"
    import InfoBlockLeadership from "./info-block--leadership"
    import InfoBlockBudget from "./info-block--budget"
    import InfoBlockTwitter from "./info-block--twitter"
    import InfoBlockEmployment from "./info-block--employment"
    import InfoBlockEmpty from "./info-block--empty"
    import ReadMoreLink from "./read-more-link"
    import RequestHandler from "@/handler/RequestHandler"
    import Preloader from "./preloader";

    export default {
        name: 'Govbuzz',
        props: [],
        components: {
            GeneralContainer,
            InfoBlockLeadership,
            InfoBlockBudget,
            InfoBlockTwitter,
            InfoBlockEmployment,
            InfoBlockEmpty,
            ReadMoreLink,
            Preloader
        },
        mounted() {
            RequestHandler.loadRecentUpdates(6, false).then(response => {
                this.updates = response.ContentList.list;
                this.loading = false;
            }).catch((error) => {
                console.error(error);
            });
        },
        methods: {
            getComponent(item) {
                if (item.class === 'EmploymentUpdateRef') {
                    return 'info-block-employment';
                }
                if (item.class === 'TweetRef') {
                    return 'info-block-twitter';
                }
                if (item.class === 'BudgetUpdateRef') {
                    return 'info-block-budget';
                }
                if (item.class === 'EmptyRef') {
                    return 'info-block-empty';
                }
                // should never get here
                return '';
            }
        },
        data() {
            return {
                title: "GovBuzz",
                customClass: "govbuzz-block",
                loading: true,
                updates: [],
            }
        }

    }
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/_variables.scss";
    @import "../../assets/scss/mixins/_bemify.scss";

    .header-title {
        font-weight: 600;
        font-family: Fira Sans,sans-serif;
        font-size: 28px;
        margin-bottom: .5em;
        color: #022c43;
        &.has-a-tooltip {
            border-bottom: 1px dashed $blue--med;
        }
    }

    .header-icon {
        max-width: 60px;
        max-height: 60px;
        display: inline-block;
        float: left;
        margin-right: 15px;
        position: relative;
        top: -10px;
    }

    .d-flex {
        display: inline-block;
        margin-top: 50px;
    }

    .c1 {
        article {
            &:nth-of-type(4), &:nth-of-type(5), &:nth-of-type(6) {
                display: none;
            }
        }
    }
    .c2 {
        article {
            &:nth-of-type(1), &:nth-of-type(2), &:nth-of-type(3) {
                display: none;
            }
        }
    }
</style>

