<template>
    <!-- Component: organisms/procurement-table-pair -->
    <div :class="'snackbar-procurement-pair snackbar-procurement-pair_'">
        <div class="snackbar-container">
            <div @click="hideFlyout()" class="close-item"></div>
            <procurement-flyout
                v-if="procurementContentId"
                v-bind="procurementProps"
                :agency="procurementProps.agency && procurementProps.agency.AgencyRef ? procurementProps.agency.AgencyRef : null"
            ></procurement-flyout>
        </div>
    </div>

    <h2 class="general-container__title table-title mt-50 table-pair-title">
        <p class="pair-expand-label pair-expand-label-A expand-label--on is-active" @click="clickCollapse('collapsing-panel');"> - <span>Hide Recent Updates / Closing Soon</span></p>
        <p class="pair-expand-label pair-expand-label-B expand-label--off" @click="clickCollapse('collapsing-panel');"> + <span>Show Recent Updates / Closing Soon</span></p>
    </h2>
    <div id="collapsing-panel" class="is-active">
        <div class="columns">
            <div class="column procurements-loading" v-if="procurementRecentLoading">
                <Preloader />
            </div>
            <div id="recent-update-container" class="column" v-if="!procurementRecentLoading && procurementsSortedRecent.length">
                <div class="table-title">Recent Updates</div>
                <div id="recent-update" class="procurementsTablePair tbl responsive-table is-active">
                    <table class="zebra mt-10" id="procurementsTableRecent">
                        <thead>
                        <tr>
                            <th class="clickable mw-110" @click="sortFieldRecent = 'agency'; sortIsAscRecent = !sortIsAscRecent; loadProcurementsRecent()" scope="col">
                                Agency <span class="sort-status"><span :class="sortFieldRecent === 'agency' && sortIsAscRecent ? 'active-sort' : 'inactive-sort'">⇩</span><span :class="sortFieldRecent === 'agency' && !sortIsAscRecent ? 'active-sort' : 'inactive-sort'">⇧</span></span>
                            </th>
                            <th class="clickable" @click="sortFieldRecent = 'title'; sortIsAscRecent = !sortIsAscRecent; loadProcurementsRecent()" scope="col">
                                Title <span class="sort-status"><span :class="sortFieldRecent === 'title' && sortIsAscRecent ? 'active-sort' : 'inactive-sort'">⇩</span><span :class="sortFieldRecent === 'title' && !sortIsAscRecent ? 'active-sort' : 'inactive-sort'">⇧</span></span>
                            </th>
                            <th class="clickable" @click="sortFieldRecent = 'last_updated_date'; sortIsAscRecent = !sortIsAscRecent; loadProcurementsRecent()" scope="col">
                                Last Update <span class="sort-status"><span :class="sortFieldRecent === 'last_updated_date' && sortIsAscRecent ? 'active-sort' : 'inactive-sort'">⇩</span><span :class="sortFieldRecent === 'last_updated_date' && !sortIsAscRecent ? 'active-sort' : 'inactive-sort'">⇧</span></span>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                            <tr v-if="!procurementRecentLoading" v-for="procurement in procurementsSortedRecent">
                                <td data-label="Agency">
                                    <a v-if="procurement.agency" :href="procurement.agency.AgencyRef.url">
                                        {{ procurement.agency.AgencyRef.name }}
                                    </a>
                                </td>
                                <td data-label="Title">
                                    <a @click="openFlyout(procurement.locationId)">
                                        {{ procurement.title }}
                                    </a>
                                </td>
                                <td data-label="Last">
                                    {{ procurement.updatedDateFormatted }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="column procurements-loading" v-if="procurementClosingLoading">
                <Preloader />
            </div>
            <div id="closing-soon-container" class="column" v-if="!procurementClosingLoading && procurementsSortedClosing.length">
                <div class="table-title">Closing Soon</div>
                <div id="closing-soon" class="procurementsTablePair tbl responsive-table is-active">
                    <table class="zebra mt-10" id="procurementsTableClosing">
                        <thead>
                        <tr>
                            <th class="clickable mw-110" @click="sortFieldClosing = 'agency'; sortIsAscClosing = !sortIsAscClosing; loadProcurementsClosing()" scope="col">
                                Agency <span class="sort-status"><span :class="sortFieldClosing === 'agency' && sortIsAscClosing ? 'active-sort' : 'inactive-sort'">⇩</span><span :class="sortFieldClosing === 'agency' && !sortIsAscClosing ? 'active-sort' : 'inactive-sort'">⇧</span></span>
                            </th>
                            <th class="clickable" @click="sortFieldClosing = 'title'; sortIsAscClosing = !sortIsAscClosing; loadProcurementsClosing()" scope="col">
                                Title <span class="sort-status"><span :class="sortFieldClosing === 'title' && sortIsAscClosing ? 'active-sort' : 'inactive-sort'">⇩</span><span :class="sortFieldClosing === 'title' && !sortIsAscClosing ? 'active-sort' : 'inactive-sort'">⇧</span></span>
                            </th>
                            <th class="clickable" @click="sortFieldClosing = 'end_date'; sortIsAscClosing = !sortIsAscClosing; loadProcurementsClosing()" scope="col">
                                Closing Date <span class="sort-status"><span :class="sortFieldClosing === 'end_date' && sortIsAscClosing ? 'active-sort' : 'inactive-sort'">⇩</span><span :class="sortFieldClosing === 'end_date' && !sortIsAscClosing ? 'active-sort' : 'inactive-sort'">⇧</span></span>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-if="!procurementClosingLoading" v-for="procurement in procurementsSortedClosing">
                            <td data-label="Agency">
                                <a v-if="procurement.agency" :href="procurement.agency.AgencyRef.url">
                                    {{ procurement.agency.AgencyRef.name }}
                                </a>
                            </td>
                            <td data-label="Title">
                                <a @click="openFlyout(procurement.locationId)">
                                    {{ procurement.title }}
                                </a>
                            </td>
                            <td data-label="End">
                                {{ procurement.endDateFormatted }}
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <!-- End Component: organisms/procurement-table-pair -->
</template>

<script>
    import Preloader from "./preloader";
    import RequestHandler from "@/handler/RequestHandler";
    import ProcurementFlyout from "./procurement-flyout";
    import {onMounted, ref} from "vue";

    export default {
        name: 'ProcurementTablePair',
        components: {
            Preloader,
            ProcurementFlyout
        },
        data() {
            return {
                procurementContentId: null,
                procurementProps: null,
            }
        },
        methods: {
            openFlyout(locationId, id) {
                RequestHandler.loadProcurement(locationId).then((data) => {
                    if (data && data.ProcurementRef) {
                        this.procurementContentId = data.ProcurementRef.contentId;
                        this.procurementProps = data.ProcurementRef;
                        var x = document.getElementsByClassName("snackbar-procurement-pair_")[0];
                        x.classList.add('show');
                        x.classList.add('fadein');
                    }
                }).catch(e => {
                    console.log(e);
                })
            },
            hideFlyout: function(id) {
                var x = document.getElementsByClassName("snackbar-procurement-pair_")[0];
                x.classList.remove('fadein');
                x.classList.add('fadeout');
                setTimeout(function(){
                    x.classList.remove('show'); x.classList.remove('fadeout');
                }, 500);
            },
            clickCollapse(elm) {
                let element = document.getElementById(elm);

                let toggleA = document.getElementsByClassName('pair-expand-label-A');
                let toggleB = document.getElementsByClassName('pair-expand-label-B');

                if (element.classList.contains('is-active')) {
                    element.classList.remove('is-active');
                    toggleA.classList.remove('is-active');
                    toggleB.classList.add('is-active');
                } else {
                    element.classList.add('is-active');
                    toggleA.classList.add('is-active');
                    toggleB.classList.remove('is-active');
                }
            }
        },
        setup(props) {
            const sortFieldRecent = ref('last_updated_date');
            const sortIsAscRecent = ref(true);
            const procurementRecent = ref([]);
            const procurementsSortedRecent = ref([]);
            const procurementRecentLoading = ref(true);

            const sortFieldClosing = ref('end_date');
            const sortIsAscClosing = ref(true);
            const procurementClosing = ref([]);
            const procurementsSortedClosing  = ref([]);
            const procurementClosingLoading = ref(true);

            const loadProcurementsRecent = () => {
                procurementRecentLoading.value = true;

                RequestHandler.loadProcurementsRecent(
                    sortFieldRecent.value,
                    sortIsAscRecent.value,
                ).then(data => {
                    procurementRecentLoading.value = false;
                    procurementRecent.value = data.ContentList.list;
                    setRecentProcurements();
                });
            };

            const setRecentProcurements = () => {
                let d = procurementRecent.value;

                function compare(a, b) {
                    if (a[sortFieldRecent.value] < b[sortFieldRecent.value]) {
                        return sortIsAscRecent.value ? -1 : 1;
                    }
                    if (a[sortFieldRecent.value] > b[sortFieldRecent.value]) {
                        return sortIsAscRecent.value ? 1 : -1;
                    }
                    return 0;
                }

                procurementsSortedRecent.value = d.sort(compare);
            };

            const loadProcurementsClosing = () => {
                procurementClosingLoading.value = true;

                RequestHandler.loadProcurementsClosing(
                    sortFieldClosing.value,
                    sortIsAscClosing.value,
                ).then(data => {
                    procurementClosingLoading.value = false;
                    procurementClosing.value = data.ContentList.list;
                    setClosingProcurements();
                });
            };

            const setClosingProcurements = () => {
                let d = procurementClosing.value;

                function compare(a, b) {
                    if (a[sortFieldClosing.value] < b[sortFieldClosing.value]) {
                        return sortIsAscClosing.value ? -1 : 1;
                    }
                    if (a[sortFieldClosing.value] > b[sortFieldClosing.value]) {
                        return sortIsAscClosing.value ? 1 : -1;
                    }
                    return 0;
                }

                procurementsSortedClosing.value = d.sort(compare);
            };

            onMounted(() => {
                loadProcurementsRecent();
                loadProcurementsClosing();
            });

            return {
                sortIsAscRecent,
                sortFieldRecent,
                procurementRecent,
                procurementsSortedRecent,
                procurementRecentLoading,
                loadProcurementsRecent,
                sortFieldClosing,
                sortIsAscClosing,
                procurementClosing,
                procurementsSortedClosing,
                procurementClosingLoading,
                loadProcurementsClosing
            };
        },
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/_variables.scss";
    @import "../../assets/scss/mixins/_bemify.scss";

    .inactive-sort {
        opacity: 0.45;
    }

    .active-sort {
        opacity: 1.0;
    }

    .tbl thead th {
        color: #fff;
        position: -webkit-sticky;
        position: sticky;
    }

    .table-title {
        text-align: center;
        font-size: 28px;
        font-weight: 600;
        margin-bottom: 0px;
    }

    .sort-status {
        span:last-of-type {
            position: relative;
            top: -1px;
        }
    }

    .procurements-loading {
        display: inline-block;
        text-align: center;
        width: 100%;
    }

    #closing-soon, #recent-update {
        display: none;
        transition: all 1s ease-in-out;
        &.is-active {
            display: block;
            padding-top: 10px;
        }
    }

    @include block('pair-expand-label') {
        display: none;
        font-weight: bold;
        padding-bottom: 0px;
        color: $blue--dark;
        padding: 5px 10px;
        margin-bottom: 0px;
        cursor: pointer;
        span {
            text-decoration: underline;
        }
        &.is-active {
            display: inline-block;
        }
    }

    .mt-5 {
        margin-top: 5px;
    }

    .mt-10 {
        margin-top: 10px;
    }

    .mt-50 {
        margin-top: 50px;
    }

    .mb-15 {
        margin-bottom: 15px;
    }

    #collapsing-panel {
        display: none;
        &.is-active {
            display: block;
        }
    }

    .table-pair-title {
        font-size: 21px;
    }

    .close-item {
        position: absolute;
        right: 20px;
        z-index: 1;
    }

    // see partial for .snackbar-procurement-pair
</style>
